import React, { useEffect, useState, useMemo } from 'react';
import s from './s.module.less';
import cx from 'classnames';
import { getTasksStatus } from 'api/home/index';
import type { TTaskItem, TTaskList, TTaskStage, TTaskData } from 'types/home';
import { ETaskType } from 'types/home';
import TaskFirstProspect from '../TaskFirstProspect';
import TaskEarnReview from '../TaskEarnReview';
import TaskListChannel from '../TaskListChannel';
import TaskTurnOnAva from '../TaskTurnOnAva';
import TaskUploadLicense from '../TaskUploadLicense';
import TaskFirstAppointment from '../TaskFirstAppointment';

type IProps = {
    isKlarity: boolean;
};

const Index: React.FC<IProps> = ({ isKlarity }) => {
    const [taskData, setTaskData] = useState<TTaskData>({
        taskList: [] as TTaskList,
        taskStage: {},
    } as TTaskData);

    const completedTasksCount = useMemo(() => {
        return taskData.taskList.filter((item) => item.completed).length;
    }, [taskData.taskList]);

    const totalTasksCount = useMemo(() => {
        return taskData.taskList.length;
    }, [taskData.taskList]);

    const isCompletedAllTask = useMemo(() => {
        return taskData.taskList.every((item) => !!item.completed);
    }, [taskData.taskList]);

    const getData = async () => {
        const { data } = await getTasksStatus();
        setTaskData(data.data);
    };

    useEffect(() => {
        getData();
    }, []);

    const renderTaskItem = ({
        index,
        task,
    }: {
        index: number;
        task: TTaskItem;
    }) => {
        if (task.taskCode === ETaskType.GET_FIRST_PROSPECT) {
            return <TaskFirstProspect index={index} task={task} isKlarity={isKlarity} />;
        }
        if (task.taskCode === ETaskType.EARN_5_STAR_REVIEWS) {
            return <TaskEarnReview index={index} task={task} isKlarity={isKlarity} />;
        }
        if (task.taskCode === ETaskType.LIST_ALL_RELEVANT_CHANNEL) {
            return (
                <TaskListChannel
                    index={index}
                    task={task}
                    isKlarity={isKlarity}
                />
            );
        }
        if (task.taskCode === ETaskType.TURN_ON_AVA_AUTO_REPLY) {
            return <TaskTurnOnAva index={index} task={task} isKlarity={isKlarity} />;
        }
        if (task.taskCode === ETaskType.UPLOAD_NEW_LICENSE) {
            return (
                <TaskUploadLicense
                    index={index}
                    task={task}
                    isKlarity={isKlarity}
                />
            );
        }
        if (task.taskCode === ETaskType.GET_FIRST_APPOINTMENT) {
            return (
                <TaskFirstAppointment
                    index={index}
                    task={task}
                    isKlarity={isKlarity}
                />
            );
        }
    };

    return (
        <div className={s.wrap}>
            {isCompletedAllTask ? (
                <div className={s.completeWrapper}>
                    <h2 className={s.completeTitle}>Upcoming goals</h2>
                    <p className={s.completeDescription}>You’re all caught up! 🎉</p>
                </div>
            ) : (
                <div className={s.taskWrapper}>
                    <h2 className={s.title}>{taskData.taskStage.name}</h2>
                    <p className={s.description}>Complete each goal to thrive!</p>
                    <div className={s.line}>
                        <i
                            className={s.progress}
                            style={{ width: `${completedTasksCount / totalTasksCount}%` }}
                        />
                    </div>
                    <p className={s.progressText}>
                        {completedTasksCount}/{totalTasksCount} goals completed
                    </p>
                    <div className={s.goalList}>
                        {taskData.taskList.map((task, index: number) => {
                            if (task.childrenTaskList.length === 0) {
                                return renderTaskItem({ task, index });
                            } else {
                                return renderTaskItem({
                                    task:
                      task.childrenTaskList.find(
                          (childTask) => !childTask.completed,
                      ) ||
                      task.childrenTaskList[task.childrenTaskList.length - 1],
                                    index,
                                });
                            }
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Index;
