import React, { useState } from 'react';
import cx from 'classnames';
import s from '../GrowYourBusiness/s.module.less';
import c from './s.module.less';
import type { TTaskItem } from 'types/home';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type IProps = {
    isKlarity: boolean;
    task: TTaskItem;
    index: number;
};

const Index: React.FC<IProps> = ({
    isKlarity,
    task,
    index,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleRoute = () => {
        navigate('/create-uni-profile?type=uniprofile&from=home');
    };

    const handleTask = () => {
        if (task.completed) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <div
                className={cx(s.listItem, task.completed ? s.completed : '')}
                onClick={() => handleTask()}
            >
                <div className={s.listInner}>
                    <i className={s.num}>{index + 1}</i>
                    <div className={s.content}>
                        <div className={s.goalTitle}>{task.taskName}</div>
                        <div className={s.goalDescription}>{task.description}</div>
                    </div>
                    <div className={s.arrow} />
                </div>
            </div>
            <Modal
                title="Get your first appointment 🚀"
                centered
                open={showModal}
                onCancel={handleHideModal}
                width={534}
                footer={null}
            >
                <div className={s.modalContent}>
                    <p>
                        Kickstart your practice growth by securing your first appointment
                        and laying the foundation for a thriving patient base!
                    </p>
                    <p>
                        Begin by optimizing your pricing strategy using Kiwi's pricing
                        intelligence tool. This data-driven approach ensures your rates
                        are both competitive and profitable, making your services
                        attractive to potential patients. With the right pricing in place,
                        you'll be well-positioned to book that crucial first appointment.
                        Remember, this initial consultation is more than just a single
                        session – it's the first step towards building a loyal patient
                        base and establishing your practice's reputation.
                    </p>
                    <div className={s.buttonWrap}>
                        <Button type="primary" onClick={() => handleRoute()}>
                            Get pricing insights
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Index;
