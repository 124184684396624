import React from 'react';
import s from './s.module.less';
import LineBar from '../LineBar';
import { useNavigate } from 'react-router-dom';

const Index: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={s.wrap}>
            <LineBar
                lineBarId="kiwi-1-1"
                icon="icon-1"
                title="Let more patients find you online"
                description="Enhance your reach via precision channel selection."
                details={`<ul>
                            <li>List on all standard channels on Kiwi Essential or Kiwi Growth Plan</li>
                            <li>Elevate your reach: select all relevant premium channels</li>
                            <li>UniProfile with a professional headshot: a high-quality photo of you in professional attire, with a friendly smile and plain backdrop</li>
                        </ul>`}
                cta="List on more channels"
                onCta={() => {
                    navigate('/dashboard/channel');
                }}
            />
            <LineBar
                lineBarId="kiwi-1-2"
                icon="icon-2"
                title="Improve your services and pricing design"
                description="Boost attraction: free consultation & pricing check."
                details={`<ul>
                        <li>Price smartly: research diverse sources to provide affordable care (recommend to look into Psychology Today and Zocdoc, or email <a target="_blank" href="mailto:support@kiwihealth.com">support@kiwihealth.com</a> for advice)</li>
                        <li>Offer consultation: allow patient prospects to establish rapport with you before they pay</li>
                    </ul>`}
                cta="Review my services"
                onCta={() => {
                    navigate('/create-uni-profile?type=uniprofile&from=home');
                }}
            />
            <LineBar
                lineBarId="kiwi-1-3"
                icon="icon-3"
                title="Grow your service offerings"
                description="Unlock new markets by adding extra licenses to your UniProfile."
                details={`<ul>
                        <li>Expand your professional footprint: upload out-of-state licenses and serve patients nationwide</li>
                        <li>Expand your practice: add licenses and diversify patient care</li>
                        </ul>`}
                cta="Upload new license"
                onCta={() => {
                    navigate('/create-uni-profile?type=uniprofile&from=home');
                }}
            />
            <LineBar
                lineBarId="kiwi-1-4"
                icon="icon-4"
                title="Nurture each prospect with care"
                description="Save time: let Ava handle rapid prospect responses."
                details={`<ul>
                        <li>Respond quickly to prospects: capture every patient opportunity</li>
                        <li>Enable Ava's instant replies: improve engagement effortlessly</li>
                    </ul>`}
                cta="Turn on Ava auto-reply"
                onCta={() => {
                    navigate('/dashboard/ava-setting-prospects');
                }}
            />
            <LineBar
                lineBarId="kiwi-1-5"
                icon="icon-5"
                title="Promote your services to build a patient base"
                description="Share your Practice Front to patients and social networks."
                details={`<ul>
                            <li>Insert your online scheduling link in Practice Front</li>
                            <li>Optimize your local SEO settings to increase local traffic</li>
                            <li>Share Practice Front with your existing patients to promote your full service menu</li>
                            <li>Increase visibility: share Practice Front on social media</li>
                        </ul>`}
                cta="Open Practice Front"
                onCta={() => {
                    navigate('/dashboard/practice-front');
                }}
            />
        </div>
    );
};

export default Index;
