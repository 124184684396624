import React, { useState } from 'react';
import cx from 'classnames';
import s from '../GrowYourBusiness/s.module.less';
import c from './s.module.less';
import type { TTaskItem } from 'types/home';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type IProps = {
    isKlarity: boolean;
    task: TTaskItem;
    index: number;
};

const Index: React.FC<IProps> = ({
    isKlarity,
    task,
    index,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleRoute = () => {
        navigate('/create-uni-profile?type=uniprofile&from=home');
    };

    const handleTask = () => {
        if (task.completed) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <div
                className={cx(s.listItem, task.completed ? s.completed : '')}
                onClick={() => handleTask()}
            >
                <div className={s.listInner}>
                    <i className={s.num}>{index + 1}</i>
                    <div className={s.content}>
                        <div className={s.goalTitle}>{task.taskName}</div>
                        <div className={s.goalDescription}>{task.description}</div>
                    </div>
                    <div className={s.arrow} />
                </div>
            </div>
            <Modal
                title="Upload your first new license 🪪"
                centered
                open={showModal}
                onCancel={handleHideModal}
                width={534}
                footer={null}
            >
                <div className={s.modalContent}>
                    <p>
                        By adding a new license, you're unlocking opportunities to offer
                        additional services or practice in new locations.
                    </p>
                    <p>
                        Diversification can lead to increased revenue streams, enhanced
                        job satisfaction, and improved resilience in a changing healthcare
                        landscape. Whether it's a specialty certification or a license for
                        a different state, each new credential you upload represents
                        growth potential for your practice.
                    </p>
                    <div className={s.buttonWrap}>
                        <Button type="primary" onClick={() => handleRoute()}>
                            Upload my new license
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Index;
