import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import cx from 'classnames';
import type { CollapseProps } from 'antd';
import { Collapse, theme } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type IProps = {
    icon: string;
    title: string;
    description: string;
    details: string;
    cta: string;
    lineBarId: string;
    onCta: () => void;
};

const Index: React.FC<IProps> = (props) => {
    const { icon, title, description, details, cta, lineBarId, onCta } = props;
    const [isClicked, setIsClicked] = useState(false);

    const checkIsItemInLocalStorage = (item: string) => {
        if (!localStorage.getItem('HOME_TOP_OPPORTUNITIES')) {
            return;
        }
        const clickedItems = JSON.parse(localStorage.getItem('HOME_TOP_OPPORTUNITIES') || '') || [];
        setIsClicked(clickedItems.includes(item));
    };

    function addToLocalStorage(item: string) {
        if (!localStorage.getItem('HOME_TOP_OPPORTUNITIES')) {
            localStorage.setItem(
                'HOME_TOP_OPPORTUNITIES',
                JSON.stringify([item]),
            );
            return;
        }
        const clickedItems = JSON.parse(localStorage.getItem('HOME_TOP_OPPORTUNITIES') || '') || [];
        if (!clickedItems.includes(item)) {
            clickedItems.push(item);
            localStorage.setItem(
                'HOME_TOP_OPPORTUNITIES',
                JSON.stringify(clickedItems),
            );
        } else {
            console.log(`${item} is already in clickedItems`);
        }
    }

    const handleClick = () => {
        addToLocalStorage(lineBarId);
        onCta();
    };
    useEffect(() => {
        checkIsItemInLocalStorage(lineBarId);
    }, []);
    return (
        <div className={s.content}>
            <div className={cx(s.iconItem, s[icon], !isClicked && s.circle)} />

            <div className={s.detail}>
                <h3 className={s.title}>{title}</h3>
                <p className={s.description}>{description}</p>
                <div className={s.viewMore}>
                    <Collapse rootClassName={s.collapse} bordered={false} ghost>
                        <Collapse.Panel
                            header={
                                <div className={s.iconWrap}>
                                    <div className={s.viewBtn}>View details</div>
                                    <i className={s.down} />
                                </div>
                            }
                            key="1"
                            showArrow={false}
                        >
                            <div
                                className={s.more}
                                dangerouslySetInnerHTML={{ __html: details }}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
            </div>
            <div className={s.activeBtn} onClick={handleClick}>
                {cta}
            </div>
        </div>
    );
};

export default Index;
