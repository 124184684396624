import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';

const Index: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={s.wrap}>
            <p className={s.description}>
                50% of your services are not competitively priced and we suggest price
                review.
            </p>
            <div
                className={s.applyButton}
                onClick={() => {
                    navigate('/create-uni-profile?type=uniprofile&from=home');
                }}
            >
                Get pricing insights
            </div>
        </div>
    );
};

export default Index;
