import React from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';

const Index: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={s.wrap}>
            <p className={s.description}>
                You have 7 patient reviews that need your response.
            </p>
            <div
                className={s.applyButton}
                onClick={() => {
                    navigate('/dashboard/klarity-reviews');
                }}
            >
                Reply to reviews
            </div>
        </div>
    );
};

export default Index;
