import React, { useState } from 'react';
import cx from 'classnames';
import s from '../GrowYourBusiness/s.module.less';
import c from './s.module.less';
import type { TTaskItem } from 'types/home';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type IProps = {
    isKlarity: boolean;
    task: TTaskItem;
    index: number;
};

const Index: React.FC<IProps> = ({
    isKlarity,
    task,
    index,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleRoute = () => {
        navigate('/create-uni-profile?type=uniprofile&from=home');
    };

    const handleTask = () => {
        if (task.completed) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <div className={cx(s.listItem, task.completed ? s.completed : '')} onClick={() => handleTask()}>
                <div className={s.listInner}>
                    <i className={s.num}>{index + 1}</i>
                    <div className={s.content}>
                        <div className={s.goalTitle}>{task.taskName}</div>
                        <div className={s.goalDescription}>{task.description}</div>
                    </div>
                    <div className={s.arrow} />
                </div>
            </div>
            <Modal
                title="Get your first prospect 🚀"
                centered
                open={showModal}
                onCancel={handleHideModal}
                width={534}
                footer={null}
            >
                <div className={s.modalContent}>
                    <p>
                        Price your services smartly to provide affordable care while
                        maintaining a sustainable practice.{' '}
                    </p>
                    <p>
                        Conducting thorough research using diverse sources is key to
                        achieving this balance. We recommend exploring platforms like
                        Psychology Today and Zocdoc, which offer insights into market
                        rates for various mental health services. These resources can
                        provide valuable benchmarks for pricing in your area and
                        specialty. If you need further guidance or have specific questions
                        about pricing strategies, don't hesitate to reach out to our
                        support team at{' '}
                        <a
                            target="_blank"
                            href="mailto:support@kiwihealth.com"
                            rel="noreferrer"
                        >
                            support@kiwihealth.com
                        </a>
                        .
                    </p>
                    <div className={s.buttonWrap}>
                        <Button type="primary" onClick={() => handleRoute()}>Review my services</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Index;
